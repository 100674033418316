import React, { createContext, useContext, ReactNode } from 'react';

interface BuyingBudgetProviderProps {
  children: ReactNode;
  hasCreatedLead: boolean;
  hasBuyingBudget: boolean;
  buyingBudgetIsSelling: boolean;
  instructionLeadCreated: boolean;
  buyingBudgetVersion: string | undefined;
}
interface BuyingBudgetContextI {
  hasBuyingBudget: boolean;
  hasCreatedLead: boolean;
  buyingBudgetIsSelling: boolean;
  instructionLeadCreated: boolean;
  buyingBudgetVersion: string | undefined;
}

const BuyingBudgetContext = createContext<BuyingBudgetContextI>({
  hasBuyingBudget: false,
  hasCreatedLead: false,
  buyingBudgetIsSelling: false,
  instructionLeadCreated: false,
  buyingBudgetVersion: undefined,
});

const BuyingBudgetProvider = ({
  children,
  hasBuyingBudget,
  hasCreatedLead,
  buyingBudgetIsSelling,
  instructionLeadCreated,
  buyingBudgetVersion,
}: BuyingBudgetProviderProps) => {
  return (
    <BuyingBudgetContext.Provider
      value={{
        hasBuyingBudget,
        hasCreatedLead,
        buyingBudgetIsSelling,
        instructionLeadCreated,
        buyingBudgetVersion,
      }}
    >
      {children}
    </BuyingBudgetContext.Provider>
  );
};

const useBuyingBudgetContext = () => useContext(BuyingBudgetContext);

export { BuyingBudgetProvider, useBuyingBudgetContext };
