const pushToDataLayer = (data: {
  [key: string]: string | boolean | number | string[] | undefined;
}) => {
  if (!(window as any).dataLayer) {
    return;
  }
  (window as any).dataLayer.push({ ...data });
};

export const Analytics = {
  pushToDataLayer,
};
