import {
  ENVIRONMENT_NAME,
  RECAPTCHA_SITE_KEY_V3,
  RECAPTCHA_TESTING_KEY_V3,
  INTEGRATION_TESTING,
} from '../../helpers/utils';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const getRecaptchaToken = (
  action: string,
  key: string,
  integration?: boolean,
): Promise<string> => {
  if (integration) {
    return Promise.resolve('');
  } else {
    return new Promise((resolve, reject) => {
      try {
        if (window?.grecaptcha?.enterprise) {
          // @ts-ignore - grecaptcha is not defined in types
          window.grecaptcha.enterprise.ready(() => {
            // @ts-ignore - grecaptcha is not defined in types
            window.grecaptcha.enterprise
              .execute(key, {
                action,
              })
              .then((token: string) => {
                resolve(token);
              });
          });
        } else {
          reject(new Error('Recaptcha is not loaded'));
        }
      } catch (error) {
        console.error(error);
      }
    });
  }
};

// set correct recaptcha key for environment
const GOOGLE_RECAPTCHA_KEY =
  ENVIRONMENT_NAME !== 'production' || INTEGRATION_TESTING
    ? RECAPTCHA_TESTING_KEY_V3
    : RECAPTCHA_SITE_KEY_V3;

// generate new recaptcha token
const generateRecaptchaToken = async (pageName: string) => {
  const recaptchaToken = await getRecaptchaToken(
    pageName,
    GOOGLE_RECAPTCHA_KEY,
  );
  return recaptchaToken;
};

// generate recaptcha payload for BE
const generateReCaptchaPayload = (token: any, action: string) => {
  const gRecaptchaConfig = {
    token,
    action,
  };

  return gRecaptchaConfig;
};

const loadReCaptcha = () => {
  const scriptIdentifier = 'recaptchaScript';
  if (!document.getElementById(scriptIdentifier)) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${GOOGLE_RECAPTCHA_KEY}`;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }
};

export {
  GOOGLE_RECAPTCHA_KEY,
  generateReCaptchaPayload,
  generateRecaptchaToken,
  getRecaptchaToken,
  loadReCaptcha,
};
