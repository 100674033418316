export type ResourceHint = {
  hintType: 'prerender' | 'preload' | 'dns-prefetch' | 'preconnect';
  uri: string;
  asType?: string;
  extraTags?: string;
};

export const globalResourceHints: ResourceHint[] = [
  { hintType: 'dns-prefetch', uri: '//dist-property-frontend-daft.daft.ie' },
  { hintType: 'dns-prefetch', uri: '//securepubads.g.doubleclick.net' },
  { hintType: 'dns-prefetch', uri: '//tag.aticdn.net' },
  { hintType: 'dns-prefetch', uri: '//static.hotjar.com' },
  { hintType: 'dns-prefetch', uri: '//cdn.wootric.com' },
  { hintType: 'dns-prefetch', uri: '//vars.hotjar.com' },
  { hintType: 'dns-prefetch', uri: 'https://logs1154.xiti.com' },
  { hintType: 'dns-prefetch', uri: '//eligibility.wootric.com' },
  { hintType: 'dns-prefetch', uri: '//script.hotjar.com' },
  { hintType: 'dns-prefetch', uri: '//adservice.google.com' },
  { hintType: 'dns-prefetch', uri: '//adservice.google.ie' },
  { hintType: 'dns-prefetch', uri: '//www.google.analytics.com' },
  { hintType: 'dns-prefetch', uri: '//google.analytics.com' },
  { hintType: 'dns-prefetch', uri: '//googletagservices.com' },
  { hintType: 'dns-prefetch', uri: '//ad.doubleclick.net' },
  { hintType: 'dns-prefetch', uri: '//tcp.google.syndication.com' },
  { hintType: 'dns-prefetch', uri: '//www.google.analytics' },
  { hintType: 'dns-prefetch', uri: '//cdn.ampproject.org' },
  { hintType: 'dns-prefetch', uri: '//googlesyndication.com' },
  {
    hintType: 'dns-prefetch',
    uri: '//dsch-content-hub-images-production.s3.eu-west-1.amazonaws.com',
  },
  {
    hintType: 'dns-prefetch',
    uri: '//dsch-content-hub-production.s3-eu-west-1.amazonaws.com',
  },
  { hintType: 'dns-prefetch', uri: '//fonts.googleapis.com' },
  { hintType: 'dns-prefetch', uri: '//s0.2mdn.net' },
  { hintType: 'dns-prefetch', uri: '//www.google.com' },
  { hintType: 'dns-prefetch', uri: 'https://www.google-analytics.com' },
  { hintType: 'preconnect', uri: 'https://www.google-analytics.com' },
  { hintType: 'dns-prefetch', uri: 'http://pagead2.googlesyndication.com' },
  { hintType: 'dns-prefetch', uri: '//fonts.gstatic.com/' },
  { hintType: 'dns-prefetch', uri: 'https://tpc.googlesyndication.com' },
  { hintType: 'dns-prefetch', uri: 'https://stats.g.doubleclick.net' },
];
