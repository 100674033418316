import { createContext } from 'react';

export type UserDetailsContextType = {
  loggedInAgentId: string | undefined;
  created_at: number;
  isUserLoggedIn: boolean;
  userId: string | undefined;
  segments: string[] | undefined;
  name: string | undefined;
  email: string | undefined;
  phone?: string | undefined;
  smsVerified?: string | undefined;
  verifiedPhone?: string | undefined;
};
export const UserDetailsContext = createContext<UserDetailsContextType>({
  loggedInAgentId: undefined,
  created_at: Math.floor(Date.now() / 1000),
  isUserLoggedIn: false,
  userId: undefined,
  segments: undefined,
  name: undefined,
  email: undefined,
  phone: undefined,
  verifiedPhone: undefined,
});
