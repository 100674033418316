import { BASE_URL, CDN_URL_STATIC_DIRECTORY, SITE_NAME } from './helpers/utils';

export default {
  title: "Daft.ie - Ireland's number one property site",
  noindex: false,
  nofollow: false,
  description:
    "Search Ireland's number one property site. 9 out of every 10 properties for sale in Ireland are advertised on Daft.ie",
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: `${BASE_URL}`,
    title: 'Daft.ie',
    description:
      "Ireland's No.1 Property Website - Smarter Property Search Starts Here",
    images: [
      {
        url: `${CDN_URL_STATIC_DIRECTORY}/static/images/meta/meta-buy-homepage.jpg`,
        alt: 'Daft.ie Logo',
      },
    ],
    site_name: `${SITE_NAME}`,
  },
  twitter: {
    handle: '@daftmedia',
    cardType: 'app',
  },
  facebook: {
    appId: '15702190222',
  },
};
