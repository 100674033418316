import axios from 'axios';

import { checkIfDayLightSavings } from '../../helpers/timezone/daylightSavings';
import { AgentUser, NextContext, PrivateUser } from '../../types';

export interface AuthoriseServiceOptions {
  /**
   * This is generally the BFF where the oauth routes have been created.
   *
   * example endpoint: ${authenticationApiUrl}/api/refresh
   */
  authenticationApiUrl: string;
}

export interface AuthoriseParams {
  /**
   * Next context object passed straight from getInitialProps or can be null
   */
  ctx?: NextContext | null;
  /**
   * Setting this will redirect to log in page if:
   *  - No user exists
   *  - The refresh token has expired or that was an issue refreshing
   * This should be set to true in cases where entire pages are inaccessible unless logged in.
   */
  forceLogIn: boolean;
  /**
   * Give a path to redirect back to after authentication has take place.
   * eg: '/about'
   *
   */
  redirectBackTo?: string;

  forceSMS?: boolean;
  trackingParams?: { [key: string]: string };
}

class AuthoriseService {
  private authenticationApiUrl: string;

  private baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    platform: 'web',
    version: '0',
    pragma: 'no-cache',
    expires: '0',
    'cache-control': 'no-cache, no-store',
  };

  constructor(options: AuthoriseServiceOptions) {
    if (!options.authenticationApiUrl) {
      throw new Error(
        'Please ensure the following have been set correctly: authenticationApiUrl',
      );
    }
    this.authenticationApiUrl = options.authenticationApiUrl;
  }

  public async getUser(ctx: NextContext | null) {
    if (ctx?.res?.locals?.user) {
      const isAgentsPage =
        this.authenticationApiUrl.endsWith('agents') ||
        this.authenticationApiUrl.endsWith('agent-v2');
      // prevent public users being logged into agent pages
      if (isAgentsPage && !ctx?.res?.locals?.user.agent_id) {
        return null;
      }
      return this.convertEmptyUserToNull(ctx.res.locals.user);
    }
    try {
      const response = await axios(`${this.authenticationApiUrl}/api/user`, {
        ...this.baseHeaders,
        method: 'POST',
      });
      return this.convertEmptyUserToNull(response.data);
    } catch (error) {
      return null;
    }
  }

  /**
   * Warning: This does not check if the user is authenticated
   */
  public async isUser(ctx: NextContext | null) {
    return Boolean(await this.getUser(ctx));
  }

  public isAgentUser(user: any): user is AgentUser {
    return (user as AgentUser).agent_id !== undefined;
  }

  public async getAgentId(ctx: NextContext | null, realm: string) {
    const currentUser = await this.getUser(ctx);

    if (currentUser && this.isAgentUser(currentUser)) {
      if (realm === 'agents_v2') {
        return currentUser.legacy_agent_id;
      } else if (realm === 'agents') {
        return currentUser.agent_id;
      }
    }
    return null;
  }

  private async convertEmptyUserToNull(user: PrivateUser | AgentUser | null) {
    return user && Object.keys(user).length > 0 ? user : null;
  }

  public async refreshToken(
    headers: any = '',
  ): Promise<AgentUser | PrivateUser> {
    try {
      const response = await axios(`${this.authenticationApiUrl}/api/refresh`, {
        headers,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      return {
        sub: '',
        email_verified: false,
        name: '',
        preferred_username: '',
        given_name: '',
        email: '',
        access_token: '',
        expires_at: 0,
        id_token: '',
        user_id: '',
        username: '',
      };
    }
  }

  public async redirectToLogin(
    ctx: NextContext | null = null,
    redirectBackTo: string,
    trackingParams = {},
    action?: string,
  ) {
    const urlTrackingParams = new URLSearchParams(trackingParams).toString();
    const isServer = typeof window === 'undefined';

    const res = ctx ? ctx.res : null;
    /*
     * Express automatically parses query parameters and breaks down into an object
     * We are encoding it so this behaviour does not happen
     * And we are redirected to the correct url with all parameters
     */
    const encodedRedirectBackTo = encodeURIComponent(redirectBackTo);
    const redirectURL = `${
      this.authenticationApiUrl
    }/auth/authenticate?redirectBackTo=${encodedRedirectBackTo}${
      urlTrackingParams ? `&${urlTrackingParams}` : ''
    }`;

    if (isServer && res) {
      const kcAction = action ? `&kc_action=${action}&` : '';
      res.writeHead(302, {
        // Location: `${this.authenticationApiUrl}/auth/authenticate?${kcAction}redirectBackTo=${redirectBackTo}`,
        Location: redirectURL + kcAction,
      });
      res.end();
    } else {
      location.assign(redirectURL);
    }
  }

  public async authorise({
    ctx = null,
    forceLogIn = false,
    forceSMS = false,
    redirectBackTo = '/',
    trackingParams = {},
  }: AuthoriseParams) {
    try {
      // TODO: Leverage local storage to minimise api hits
      let token: string | null = null;
      let tokenExpiry: number | null = null;
      let headers = null;
      const user: AgentUser | PrivateUser | null = await this.getUser(ctx);

      if (user) {
        token = user!.access_token;
        tokenExpiry = user!.expires_at;
      }

      if (
        (!user && forceLogIn) ||
        (forceSMS &&
          !(user as unknown as AgentUser | PrivateUser)?.sms_verified &&
          !(user as unknown as AgentUser | PrivateUser)?.impersonator)
      ) {
        const action = forceSMS ? 'sms_session_verify' : undefined;
        this.redirectToLogin(ctx, redirectBackTo, trackingParams, action);
      }

      if (tokenExpiry) {
        const timeNow = Math.floor(Date.now() / 1000);
        let currentExpiry: number;

        if (checkIfDayLightSavings()) {
          const newDate = new Date(0);
          const currentTime = newDate.setUTCSeconds(tokenExpiry);
          currentExpiry = currentTime;
        } else {
          currentExpiry = tokenExpiry;
        }
        // allows refresh to happen before expiry
        const seconds = 60;
        const tokenExpiryLessOneMinute: number = currentExpiry - seconds;

        if (tokenExpiryLessOneMinute < timeNow) {
          headers = ctx && ctx.req && ctx.req.headers ? ctx.req.headers : null;
          const userInfo: AgentUser | PrivateUser =
            await this.refreshToken(headers);
          if (!Object.keys(userInfo).length || userInfo.sub.length < 1) {
            if (forceLogIn) {
              this.redirectToLogin(ctx, redirectBackTo, trackingParams);
            }
            return null;
          }
          token = userInfo.access_token;
        }
      }

      return token;
    } catch (error) {
      console.warn('authUnity authorise: ', error);
    }
    return null;
  }
}

export { AuthoriseService };
