import { DefaultTheme as StyledComponentDefaultTheme } from 'styled-components';
import {
  DaftThemeTypes,
  TBrand,
  TColor,
  TFontSize,
  TFontSizes,
  TFontWeight,
  TSpacing,
} from './theme.types';

const BLUE: TColor = {
  BASE: '#4170c4',
  LIGHT: '#638ace',
  LIGHTER: '#cbd8ee',
  LIGHTEST: '#f0f4fa',
  DARK: '#3560ab',
  DARKER: '#30528f',
  TEXTCONTRAST: '#fff',
};

const GREEN: TColor = {
  DARKER: '#0a572e',
  DARK: '#046b34',
  BASE: '#008940',
  LIGHT: '#54B883',
  LIGHTER: '#95e5bb',
  LIGHTEST: '#cff3e0',
  TEXTCONTRAST: '#fff',
};

const GREY: TColor = {
  DARKER: '#333333',
  DARK: '#717171',
  BASE: '#999999',
  LIGHT: '#bbbbbb',
  LIGHTER: '#dddddd',
  LIGHTEST: '#f4f4f4',
  LIGHTEST2: '#F8F8F8',
  TEXTCONTRAST: '#fff',
};

const RED: TColor = {
  DARKER: '#7a1616',
  DARK: '#a30a0a',
  BASE: '#d50000',
  LIGHT: '#e57e7e',
  LIGHTER: '#F5C4C4',
  LIGHTEST: '#fae6e6',
  TEXTCONTRAST: '#fff',
};

const FONT_SIZE: TFontSize = {
  S10: 'font-size: 0.625rem; line-height: 1rem;', // 10px when base is 16px
  S12: 'font-size: 0.75rem; line-height: 1rem;',
  S14: 'font-size: 0.875rem; line-height: 1.25rem;',
  M14: 'font-size: 0.875rem; line-height: 1.5rem;',
  M16: 'font-size: 1rem; line-height: 1.5rem;',
  L16: 'font-size: 1rem; line-height: 2rem;',
  L18: 'font-size: 1.125rem; line-height: 1.5rem;',
  L20: 'font-size: 1.25rem; line-height: 2rem;',
  L24: 'font-size: 1.5rem; line-height: 2rem;',
  XL28: 'font-size: 1.75rem; line-height: 2.5rem;',
  XL36: 'font-size: 2.25rem; line-height: 3rem;',
  XL44: 'font-size: 2.75rem; line-height: 3rem;',
};

const FONT_SIZES: TFontSizes = {
  S10: { fontSize: '0.625rem', lineHeight: '1rem' }, // 10px when base is 16px
  S12: { fontSize: '0.75rem', lineHeight: '1rem' },
  S14: { fontSize: '0.875rem', lineHeight: '1.25rem' },
  M14: { fontSize: '0.875rem', lineHeight: '1.5rem' },
  M16: { fontSize: '1rem', lineHeight: '1.5rem' },
  L16: { fontSize: '1rem', lineHeight: '2rem' },
  L18: { fontSize: '1.125rem', lineHeight: '1.5rem' },
  L20: { fontSize: '1.25rem', lineHeight: '2rem' },
  L24: { fontSize: '1.5rem', lineHeight: '2rem' },
  XL28: { fontSize: '1.75rem', lineHeight: '2.5rem' },
  XL36: { fontSize: '2.25rem', lineHeight: '3rem' },
  XL44: { fontSize: '2.75rem', lineHeight: '3rem' },
};

const FONT_WEIGHT: TFontWeight = {
  REGULAR: '400',
  SEMIBOLD: '600',
  BOLD: '700',
};

const SPACING: TSpacing = {
  S0: '0',
  S2: '0.125rem', // originally 0.2rem
  S4: '0.25rem', // originally 0.4rem
  S8: '0.5rem', // originally 0.8rem
  S12: '0.75rem', // originally 1.2rem
  M16: '1rem', // originally 1.6rem
  M20: '1.25rem', // originally 2rem
  M24: '1.5rem', // originally 2.4rem
  M32: '2rem', // originally 3.2rem
  L40: '2.5rem', // originally 4rem
  L48: '3rem', // originally 4.8rem
  L56: '3.5rem', // originally 5.6rem
  XL64: '4rem', // originally 6.4rem
  XL72: '4.5rem', // originally 7.2rem
  XL80: '5rem', // originally 8rem
  XL88: '5.5rem', // originally 8.8rem
};

const brand: TBrand = {
  primary: BLUE,
  error: RED,
  warning: RED,
  success: GREEN,
  grey: GREY,
  text: {
    primary: GREY.LIGHTER,
    secondary: GREY.DARK,
    disabled: GREY.DARK,
  },
  background: {
    primary: '#ffffff',
    secondary: GREY.DARK,
    disabled: GREY.DARK,
    hover: GREY.LIGHTER,
    selected: GREY.LIGHTER,
  },
};

export const breakpoints = {
  xs: 0,
  sm: 440,
  m: 640,
  lg: 1024,
};

const mediaQuery = {
  xsmallOnly: `(max-width:${breakpoints.sm - 1}px)`,
  small: `(min-width:${breakpoints.sm}px)`,
  smallOnly: `(max-width:${breakpoints.m - 1}px)`,
  medium: `(min-width:${breakpoints.m}px)`,
  mediumOnly: `(max-width:${breakpoints.lg - 1}px)`,
  large: `(min-width:${breakpoints.lg}px)`,
  largeOnly: `(max-width:${breakpoints.lg - 1}px)`,
};

const daftTheme: DaftThemeTypes = {
  color: {
    PRIMARY_DARKER: '#30528f',
    PRIMARY_DARK: '#3560ab',
    PRIMARY: '#4170c4',
    PRIMARY_LIGHT: '#638ace',
    PRIMARY_LIGHTER: '#cbd8ee',
    PRIMARY_LIGHTEST: '#f0f4fa',

    GREY_DARKER: '#333333',
    GREY_DARK: '#717171',
    GREY: '#999999',
    GREY_LIGHT: '#bbbbbb',
    GREY_LIGHTER: '#dddddd',
    GREY_LIGHTEST: '#f4f4f4',

    SUCCESS_DARKER: '#0a572e',
    SUCCESS_DARK: '#046b34',
    SUCCESS: '#008940',
    SUCCESS_LIGHT: '#54B883',
    SUCCESS_LIGHTER: '#95e5bb',
    SUCCESS_LIGHTEST: '#cff3e0',

    ERROR_DARKER: '#7a1616',
    ERROR_DARK: '#a30a0a',
    ERROR: '#d50000',
    ERROR_LIGHT: '#e57e7e',
    ERROR_LIGHTER: '#fsc4c4',
    ERROR_LIGHTEST: '#fae6e6',

    WHITE: '#ffffff',
  },
  fontWeight: {
    REGULAR: '400',
    SEMIBOLD: '600',
    BOLD: '700',
  },
  fontSize: {
    P14: 'font-size: 14px; line-height: 24px;',
    P16: 'font-size: 16px; line-height: 28px;',
    P20: 'font-size: 20px; line-height: 32px;',
    B10: 'font-size: 10px; line-height: 14px;',
    B12: 'font-size: 12px; line-height: 18px;',
    S14: 'font-size: 14px; line-height: 20px;',
    S16: 'font-size: 16px; line-height: 24px;',
    T18: 'font-size: 18px; line-height: 26px;',
    T20: 'font-size: 20px; line-height: 28px;',
    T24: 'font-size: 24px; line-height: 36px;',
    T28: 'font-size: 28px; line-height: 42px;',
    H36: 'font-size: 36px; line-height: 50px;',
    HM36: 'font-size: 36px; line-height: 44px;',
    H44: 'font-size: 44px; line-height: 54px;',
  },
  border: {
    radius: {
      STANDARD: '4px',
    },
    width: {
      STANDARD: '1px',
      MEDIUM: '2px',
    },
  },
  spacing: {
    S2: '2px',
    S4: '4px',
    S8: '8px',
    S12: '12px',
    M16: '16px',
    M20: '20px',
    M24: '24px',
    M32: '32px',
    L40: '40px',
    L48: '48px',
    L56: '56px',
    L64: '64px',
    XL72: '72px',
    XL80: '80px',
    XL88: '88px',
  },
};
export const DefaultTheme: StyledComponentDefaultTheme = {
  ...daftTheme,
  toolkit: {
    colours: {
      BLUE,
      GREEN,
      GREY,
      RED,
    },
    brand,
    spacing: SPACING,
    typography: {
      fontSizes: FONT_SIZE,
      fontWeights: FONT_WEIGHT,
      lineHeights: FONT_SIZE,
      letterSpacings: SPACING,
    },
    borderRadius: {
      S: SPACING.S2,
      M: SPACING.S4,
      L: SPACING.S8,
      XL: SPACING.S12,
    },
    pageContainers: {
      md: '3',
      lg: '65',
    },
    breakpoints: {
      xs: '0', //phone
      sm: '440', // tablet
      md: '640', // small laptop
      lg: '1024', // large screen
    },
    mediaQuery,
    components: {
      text: {
        base: {
          color: brand.text?.primary,
        },
        intent: {
          primary: {
            backgroundColor: brand.primary.BASE,
            color: brand.primary.TEXTCONTRAST,
            ':hover': {
              backgroundColor: brand.primary.DARK,
            },
          },
        },
        sizes: {
          S: {
            fontSize: FONT_SIZE.S12,
          },
          M: {
            fontSize: FONT_SIZE.M16,
          },
          L: {
            fontSize: FONT_SIZE.L18,
          },
          XL: {
            fontSize: FONT_SIZE.XL36,
          },
        },
      },
      button: {
        base: {
          backgroundColor: brand.background.primary,
          borderRadius: '0.25rem', // originally 0.4rem
          color: brand.text.secondary,
          cursor: 'pointer',
          transition: 'background-color 0.5s ease-in-out',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          fontWeight: FONT_WEIGHT.BOLD,
          fontSize: '0.875rem', // originally 1.4rem
        },
        intent: {
          primary: {
            backgroundColor: brand.primary.BASE,
            color: brand.primary.TEXTCONTRAST,
            ':hover': {
              backgroundColor: brand.primary.DARK,
            },
          },
        },
        buttonSizes: {
          X_SMALL: {
            height: `${SPACING.M32}`, // originally 3.2rem
            padding: `${SPACING.S4} ${SPACING.S8}`,
          },
          SMALL: {
            height: `${SPACING.L40}`, // originally 4.0rem
            padding: `${SPACING.S8} ${SPACING.S12}`,
          },
          MEDIUM: {
            height: `${SPACING.L48}`, // originally 4.8rem
            padding: `${SPACING.S12} ${SPACING.M16}`,
          },
          LARGE: {
            height: `${SPACING.XL64}`, // originally 6.4rem
            padding: `${SPACING.M20} ${SPACING.M16}`,
          },
        },
      },
      badge: {
        base: {
          display: 'inline-flex',
          alignItems: 'center',
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          fontWeight: FONT_WEIGHT.SEMIBOLD,
          borderRadius: SPACING.S4,
          padding: `${SPACING.S4}${SPACING.S8}`,
          gap: SPACING.S8,
        },
        style: {
          OUTLINED: {
            border: `1px solid var(--border-color)`,
            color: `var(--color)`,
            background: brand.primary.TEXTCONTRAST,
          },
          FILLED_DARK: {
            border: `1px solid var(--border-color)`,
            background: `var(--background-color)`,
            color: `var(--color)`,
          },
          FILLED_LIGHT: {
            border: `1px solid var(--border-color)`,
            background: `var(--background-color)`,
            color: `var(--color)`,
          },
          OVERLAY: {
            background: `rgba(51, 51, 51, 0.8)`,
            color: brand.error.TEXTCONTRAST,
          },
        },
        color: {
          RED: {
            OUTLINED: {
              '--border-color': brand.error.BASE,
              '--color': brand.error.BASE,
            },
            FILLED_DARK: {
              '--border-color': brand.error.BASE,
              '--background-color': brand.error.BASE,
              '--color': brand.error.TEXTCONTRAST,
            },
            FILLED_LIGHT: {
              '--border-color': brand.error.LIGHTEST,
              '--background-color': brand.error.LIGHTEST,
              '--color': brand.error.DARK,
            },
          },
          GREY_DARKER: {
            OUTLINED: {
              '--border-color': brand.grey?.DARKER,
              '--color': brand.grey?.DARKER,
            },
            FILLED_DARK: {
              '--border-color': brand.grey?.DARKER,
              '--background-color': brand.grey?.DARKER,
              '--color': brand.primary.TEXTCONTRAST,
            },
            FILLED_LIGHT: {
              '--border-color': brand.grey?.LIGHTER,
              '--background-color': brand.grey?.LIGHTER,
              '--color': brand.grey?.DARKER,
            },
          },
          BLUE: {
            OUTLINED: {
              '--border-color': brand.primary.BASE,
              '--color': brand.primary.BASE,
            },
            FILLED_DARK: {
              '--border-color': brand.primary.BASE,
              '--background-color': brand.primary.BASE,
              '--color': brand.primary.TEXTCONTRAST,
            },
            FILLED_LIGHT: {
              '--border-color': brand.primary.LIGHTEST,
              '--background-color': brand.primary.LIGHTEST,
              '--color': brand.primary.DARK,
            },
          },
          GREEN: {
            OUTLINED: {
              '--border-color': brand.success.BASE,
              '--color': brand.success.BASE,
            },
            FILLED_DARK: {
              '--border-color': brand.success.BASE,
              '--background-color': brand.success.BASE,
              '--color': brand.success.TEXTCONTRAST,
            },
            FILLED_LIGHT: {
              '--border-color': brand.success.LIGHTEST,
              '--background-color': brand.success.LIGHTEST,
              '--color': brand.success.DARK,
            },
          },
          GREY_DARK: {
            OUTLINED: {
              '--border-color': brand.grey?.DARK,
              '--color': brand.grey?.DARK,
            },
            FILLED_DARK: {
              '--border-color': brand.grey?.DARK,
              '--background-color': brand.grey?.DARK,
              '--color': brand.grey?.TEXTCONTRAST,
            },
            FILLED_LIGHT: {
              '--border-color': brand.grey?.LIGHTEST,
              '--background-color': brand.grey?.LIGHTEST,
              '--color': brand.grey?.DARKER,
            },
          },
        },
        size: {
          SMALL: {
            height: SPACING.M20,
            padding: `${SPACING.S2} ${SPACING.S4}`,
            ...FONT_SIZES.S10,
          },
          MEDIUM: {
            height: SPACING.M24,
            padding: `${SPACING.S4} ${SPACING.S8}`,
            ...FONT_SIZES.S12,
          },
          LARGE: {
            height: '1.75rem', //originally 2.8rem
            padding: `${SPACING.S4} ${SPACING.S8}`,
            ...FONT_SIZES.S14,
          },
        },
      },
      promopanel: {
        base: {
          borderRadius: '0.25rem', // originally 0.4rem
          width: '100%',
          padding: '2rem',
        },
        variants: {
          BLUE: {
            backgroundColor: BLUE.LIGHTER,
          },
        },
      },

      link: {
        base: {
          ':hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        },
        variants: {
          PRIMARY: {
            color: BLUE.BASE,
            ':hover': {
              color: BLUE.DARKER,
            },
            ':focus': {
              color: BLUE.DARKER,
            },
            ':active': {
              color: BLUE.LIGHT,
            },
            ':disabled': {
              color: BLUE.LIGHT,
            },
          },
          SECONDARY: {
            color: GREY.DARKER,
            ':hover': {
              color: GREY.DARK,
            },
            ':disabled': {
              color: GREY.LIGHTER,
            },
          },
        },
        size: {
          SMALL: { ...FONT_SIZES.S12 },
          MEDIUM: { ...FONT_SIZES.M14 },
          LARGE: { ...FONT_SIZES.M16 },
        },
        type: {
          INLINE: { display: 'inline-flex' },
          STANDALONE: { display: 'block' },
        },
        weights: {
          REGULAR: FONT_WEIGHT.REGULAR,
          SEMIBOLD: FONT_WEIGHT.SEMIBOLD,
          BOLD: FONT_WEIGHT.BOLD,
        },
      },

      TabButton: {
        base: {
          fontWeight: FONT_WEIGHT.SEMIBOLD,
          color: GREY.DARKER,
          ':hover': {
            background: GREY.LIGHTEST,
            color: GREY.DARKER,
            borderRadius: SPACING.S4,
          },
          ':focus-visible': {
            outline: `${SPACING.S4} solid ${GREY.LIGHT}`,
            borderRadius: SPACING.S4,
            color: GREY.DARKER,
          },
          ':disabled': {
            color: GREY.LIGHT,
            background: 'transparent',
            pointerEvents: 'none',
          },
        },
        variants: {
          DARKACTIVE: {
            textDecoration: `2px solid underline ${BLUE.BASE}`,
            textUnderlineOffset: SPACING.S8,
            color: BLUE.BASE,
          },
          LIGHT: {
            color: BLUE.TEXTCONTRAST,
            ':hover': {
              background: GREY.LIGHTEST,
              color: GREY.DARKER,
              borderRadius: SPACING.S4,
            },
            ':focus-visible': {
              outline: `${SPACING.S4} solid ${GREY.LIGHT}`,
              borderRadius: SPACING.S4,
              color: BLUE.TEXTCONTRAST,
            },
            ':disabled': {
              background: GREY.LIGHT,
              pointerEvents: 'none',
            },
          },
          LIGHTACTIVE: {
            textDecoration: `2px solid underline ${BLUE.TEXTCONTRAST}`,
            textUnderlineOffset: SPACING.S8,
            color: BLUE.TEXTCONTRAST,
          },
        },
      },
    },
  },
};
