import React from 'react';

import { NewTabIcon } from '@dsch/icons';
import styled from 'styled-components';

const Banner = styled.div`
  background-color: #eeeeee;
  text-align: right;
  padding: 8px 20px;
  position: relative;
`;

const Container = styled.div`
  max-width: 1040px;
  margin: 0 auto;
  font-size: 12px;
`;

const LinkContent = styled.div`
  display: inline-flex;
  align-items: center;

  svg {
    font-size: 14px;
  }

  span {
    font-size: 12px;
    padding-left: 4px;
  }
`;

const LinkBack = () => (
  <Container>
    <a href="https://www.daft.ie/">
      <LinkContent>
        <NewTabIcon />
        <span> Use old Daft</span>
      </LinkContent>
    </a>
  </Container>
);

export const LegacyBanner = () => (
  <Banner>
    <LinkBack />
  </Banner>
);
