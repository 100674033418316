export const checkIfDayLightSavings = (date?: Date) => {
  // set a new date if the setDate parameter is empty
  const setDate = date ? date : new Date();

  // set the timeZoneOffset for January and July.
  const january = new Date(setDate.getFullYear(), 0, 1).getTimezoneOffset();
  const july = new Date(setDate.getFullYear(), 6, 1).getTimezoneOffset();

  // get the largest value from the two dates and compare to the current dates timeZoneOffset. Returns a boolean to confirm if it's daylight savings or not.
  return Math.max(january, july) !== setDate.getTimezoneOffset();
};

export const addHoursToDate = (date: Date, hours: number) => {
  // add extra hours to the date
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
};
