export const privateUser = {
  sub: '68d27fc6-53e9-4096-a6d4-0e57df9645fc',
  email_verified: false,
  user_id: '1619756',
  name: 'Private User',
  preferred_username: 'userPrivate',
  given_name: 'Private User',
  email: 'userPrivate@gmail.com',
  access_token: 'fake-access-user-token',
  expires_at: 1747828263,
  id_token: 'fake-id-token',
  user_details: {
    email: 'userPrivate@gmail.com',
    verifiedPhone: '353831111111',
    userAttributes: [
      {
        key: 'buying_budget',
        value: '500000',
      },
      {
        key: 'mortgage_lead_created',
        value: 'true',
      },
      {
        key: 'buying_budget_is_selling',
        value: 'true',
      },
      {
        key: 'instruction_lead_created',
        value: 'false',
      },
      {
        key: 'buying_budget_version',
        value: '1',
      },
      {
        key: 'buying_budget_months_to_target',
        value: '1',
      },
    ],
    userId: '1619756',
    contactInfo: {
      phone: '353831111111',
    },
  },
};
