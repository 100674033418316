import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { rg4js } from '../helpers/monitoring/raygun';
import SmartSessionStorage from '../helpers/storage/SmartSessionStorage';

// NB: Pulse refers to enabling Real User Monitoring (RUM) in Raygun
// Currently, we are enabling Pulse for 30% of users, this value is based on the enablePulsePercentage parameter

const useRaygun = (raygunEnabled: boolean, enablePulsePercentage: number) => {
  const router = useRouter();

  //Only set this to true manually in the application tab in the dev tools, otherwise it's always false
  const debugMode = Boolean(
    typeof window !== 'undefined' && SmartSessionStorage.get('debug') === true,
  );
  // eslint-disable-next-line no-console
  if (debugMode) console.log('Debug Mode Enable 🐞🐛️🛠️💻️️');

  if (enablePulsePercentage > 100 || enablePulsePercentage < 0) {
    console.error('Please provide a correct percentage between 0-100%');
  }

  //Check for enablePulse in session storage, if none, set randomly based on % provided
  if (typeof window !== 'undefined') {
    const pulseValueExistsForUser =
      SmartSessionStorage.get('enablePulse') !== null;
    if (!pulseValueExistsForUser) {
      const randomNumber = Math.random();
      if (randomNumber <= enablePulsePercentage / 100) {
        SmartSessionStorage.set('enablePulse', true);
      } else {
        SmartSessionStorage.set('enablePulse', false);
      }
    }
  }

  const enablePulse =
    typeof window !== 'undefined'
      ? Boolean(SmartSessionStorage.get('enablePulse') === true)
      : false;

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      rg4js('trackEvent', {
        type: 'pageView',
        path: url,
      });
    };

    raygunEnabled && router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      raygunEnabled && router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  return {
    enablePulse,
    debugMode,
  };
};

export { useRaygun };
