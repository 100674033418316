enum PURPOSES {
  MEASURE_AD_PERFORMANCE = 'measure_ad_performance',
  MEASURE_CONTENT_PERFORMANCE = 'measure_content_peformance',
  FUNCTIONAL = 'functional-nYa6XFUV',
  COOKIES = 'cookies',
  CREATE_ADS_PROFILE = 'create_ads_profile',
  SELECT_PERSONALIZED_ADS = 'select_personalized_ads',
  SELECT_BASIC_ADS = 'select_basic_ads',
  IMPROVE_PRODUCTS = 'improve_products',
  MARKET_RESEARCH = 'market_research',
}

// Vendor IDs available here: https://console.didomi.io/distilled-sch/consent-notices/vendors-list/zRLTDmWA
enum VENDORS {
  RAYGUN = 'c:raygun-XQkKK4DB',
}

type Enabled = {
  enabled: string[];
};

type DidomiToken = {
  user_id: string;
  created: string;
  updated: string;
  vendors: Enabled;
  purposes: Enabled;
  vendors_li: Enabled;
  version: number;
};

export { PURPOSES, VENDORS };
export type { DidomiToken };
