import { AUTHENTICATION_API_BASE_URL } from '../../helpers/utils';
import { NextContext } from '../../types';

import { AuthoriseParams, AuthoriseService } from './AuthoriseService';

const authoriseServices = {
  daft: new AuthoriseService({
    authenticationApiUrl: `${AUTHENTICATION_API_BASE_URL}`,
  }),
  agents: new AuthoriseService({
    authenticationApiUrl: `${AUTHENTICATION_API_BASE_URL}/agents`,
  }),
  agents_v2: new AuthoriseService({
    authenticationApiUrl: `${AUTHENTICATION_API_BASE_URL}/agent-v2`,
  }),
};

const authorise = async (
  params: AuthoriseParams,
  realm: 'daft' | 'agents' | 'agents_v2',
): Promise<string | null> => {
  return authoriseServices[realm].authorise(params);
};

const refreshToken = async (
  realm: 'daft' | 'agents' | 'agents_v2',
): Promise<any> => {
  return authoriseServices[realm].refreshToken();
};

const isUser = async (
  ctx: NextContext | null,
  realm: 'daft' | 'agents' | 'agents_v2' = 'daft',
): Promise<boolean> => authoriseServices[realm].isUser(ctx);

const getAgentId = async (
  ctx: NextContext | null,
  realm: 'daft' | 'agents' | 'agents_v2' = 'daft',
): Promise<any> => authoriseServices[realm].getAgentId(ctx, realm);

export { authorise, isUser, refreshToken, getAgentId };
