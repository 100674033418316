import React from 'react';

import Modal from 'react-modal';

export const setAppElement = (el: HTMLElement | null | string) => {
  if (el) {
    Modal.setAppElement(el);
  }
};

const ReactModalAdapter = ({ className, ...props }: any) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  );
};

export default ReactModalAdapter;
