/* eslint-disable prefer-rest-params */

import { globalResourceHints, ResourceHint } from '../config/resourceHints';
import {
  Dashboard,
  Listing,
  PropertyCounts,
  SECTIONS,
  Seller,
  SellerType,
  SubUnit,
} from '../types';

export const renderResourceHints = (resourceHints: ResourceHint[] = []) =>
  resourceHints
    .concat(globalResourceHints)
    .map(({ hintType, uri, asType }: ResourceHint, index: number) => (
      <link key={index} rel={hintType} href={uri} as={asType} />
    ));

// This is used for HOC components which return prop getter functions,
// the callAll allows us to pass, for example, onClick handlers through the prop getter function
// while making sure we don't overwrite our internal onClick handler.
// That is, we can make sure our HOC handles it's internal state correctly, whilst also allowing
// the user of the HOC to add their own handlers wherever necessary
// a basic example: https://kentcdodds.com/blog/mixing-component-patterns
export const callAll =
  (...fns: Function[]) =>
  (...args: any) =>
    fns.forEach((fn: any) => {
      if (fn) {
        fn(...args);
      }
    });

/**
 * Environment variables
 * These are set in the .env file and are used to configure the app
 * based on the environment it is running in.
 */

// API keys
export const AUTOADDRESS_KEY = process.env.NEXT_PUBLIC_AUTOADDRESS_KEY;
export const CONVERT_KEY = process.env.CONVERT_KEY;
export const DIDOMI_API_KEY = process.env.NEXT_PUBLIC_DIDOMI_API_KEY;
export const RAYGUN_API_KEY = process.env.NEXT_PUBLIC_RAYGUN_API_KEY;

// Authentication
export const AUTHENTICATION_API_BASE_URL =
  process.env.NEXT_PUBLIC_AUTHENTICATION_API_BASE_URL;
export const AUTHENTICATION_SERVER_URL =
  process.env.NEXT_PUBLIC_AUTHENTICATION_SERVER_URL;
export const KC_CLIENT_ID = process.env.NEXT_PUBLIC_KC_CLIENT_ID;
export const KC_SCOPE = process.env.NEXT_PUBLIC_KC_SCOPE;
export const KC_RESPONSE_TYPE = process.env.NEXT_PUBLIC_KC_RESPONSE_TYPE;

// Base URLs
export const API_URL_PRISMIC = process.env.NEXT_PUBLIC_API_URL_PRISMIC;
export const BASE_CDN_URL = process.env.NEXT_PUBLIC_BASE_CDN_URL;
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

// Environment settings
export const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
export const DEV_ENV = process.env.NEXT_PUBLIC_DEV_ENV === 'true';
export const ENVIRONMENT_NAME = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME;
export const FORCE_SMS_ENV = process.env.NEXT_PUBLIC_FORCE_SMS_ENV;
export const PREVIEW_ENV = process.env.NEXT_PUBLIC_PREVIEW_ENV === 'true';
export const PRODUCTION_ENV = process.env.NEXT_PUBLIC_PRODUCTION_ENV === 'true';
export const STAGING_ENV = process.env.NEXT_PUBLIC_STAGING_ENV === 'true';

// Google services
export const DFP_NETWORK_ID = process.env.NEXT_PUBLIC_DFP_NETWORK_ID;
export const GOOGLE_TAG_MANAGER = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER;
export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
export const RECAPTCHA_SITE_KEY_V3 =
  process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY_V3;
export const RECAPTCHA_TESTING_KEY =
  process.env.NEXT_PUBLIC_RECAPTCHA_TESTING_KEY;
export const RECAPTCHA_TESTING_KEY_V3 =
  process.env.NEXT_PUBLIC_RECAPTCHA_TESTING_KEY_V3;

// Legacy settings
export const LEGACY_REDIRECT_URL = process.env.NEXT_PUBLIC_LEGACY_REDIRECT_URL;
export const LEGACY_REDIRECT_FALLBACK_URL =
  process.env.NEXT_PUBLIC_LEGACY_REDIRECT_FALLBACK_URL;

// Other settings
export const DAFT_METADATA_SERVICE = process.env.DAFT_METADATA_SERVICE;
export const DIDOMI_NOTICE_ID = process.env.NEXT_PUBLIC_DIDOMI_NOTICE_ID;
export const HUBVISOR_ID = process.env.NEXT_PUBLIC_HUBVISOR_ID;
export const INTEGRATION_TESTING = process.env.NEXT_PUBLIC_INTEGRATION_TESTING;
export const SEARCH_SERVICE = process.env.SEARCH_SERVICE;
export const SITE_NAME = process.env.NEXT_PUBLIC_SITE_NAME;

/**
 * Here we build up the CDN URL for busting the cache. We only need this
 * in environments where the CDN is applied. We also disable it on previews for now.
 */
export const CDN_URL_STATIC_DIRECTORY =
  process.env.NEXT_PUBLIC_BASE_CDN_URL || '';

// getRootNode polyfill. Uses node.getRootNode if possible, otherwise
// recursively calls itself with the node.parentNode until we find the rootNode.
export const getRootNode = (node: Node): any => {
  if (node.getRootNode) {
    return node.getRootNode();
  }
  if (node.parentNode !== null) {
    return getRootNode(node.parentNode);
  }

  return node;
};

export const baseHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  brand: 'daft',
  platform: 'web',
  version: '0',
};

// used to safely get a deeply nested value
// eg. getting   object.property.aNestedArray[0].oneMoreThing
// can be a pain in terms of defensiveness
// so we can use: safeGet(object, ['property', 'aNestedArray', 0, 'oneMoreThing'])
// to get the nested value without an error occuring if one of the properties doesn't exist.
// we can also provide a fallback value if we fail to get our deeply nested value.
// future JS syntax proposes: object.property?.thing?.anotherThing type syntax, which could be
// introduced right now using babel if it were agreed.
export const safeGet = (
  object: Object | any[] | undefined,
  pathArray: any[],
  fallback: any = undefined,
) => {
  if (object === 'undefined') {
    return fallback;
  }
  const target = pathArray.reduce(
    (obj, property) => obj && obj[property],
    object,
  );
  return typeof target !== 'undefined' ? target : fallback;
};

export const upperCaseFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

// (hacky...) this is used to pull the url value for property type out of URL
// and prepare it for being displayed on the page. We do this because of
// complexities in pluralising the property type from the filter value.
// since the url value is already pluralised, we use that...
export const formatUrlValueForDisplay = (text: string) =>
  text.split('-').map(upperCaseFirstLetter).join(' ');

type TextHighlightChildren = {
  preText: string;
  highlightedText?: string;
  postText?: string;
};

/**
 * The text highlighter is a HOC which is used to highlight a
 * substring within a given string. It works by providing the child component
 * with three sets of text: preText, highlightedText, postText.
 * This allows the child component to render the text how it needs to when it stiches these three
 * strings together in HTM:. Usually meaning that
 * preText and postText would have the same style and the highlightedText would be a notably
 * different style.
 *
 * eg.
 *
 * text: 'Harolds Cross',
 * searchString: 'old'
 * =>
 * preText: 'Har',
 * highlightedText: 'old',
 * postText: 's Cross'
 * */
export const TextHighlighter = (props: {
  text: string;
  searchString: string;
  children: (props: TextHighlightChildren) => JSX.Element;
}) => {
  const { text, searchString } = props;
  let childProps: TextHighlightChildren = {
    preText: text,
  };

  if (text && searchString) {
    const start = text.toLowerCase().indexOf(searchString.toLowerCase());
    const end = start + searchString.length;
    if (start > -1) {
      childProps = {
        preText: text.slice(0, start),
        highlightedText: text.slice(start, end),
        postText: text.slice(end, text.length),
      };
    }
  }
  return props.children(childProps);
};

export const isElementInView = (element: HTMLElement): boolean => {
  const rect = element.getBoundingClientRect();

  return Boolean(
    rect.bottom > 0 &&
      rect.right > 0 &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
      rect.top < (window.innerHeight || document.documentElement.clientHeight),
  );
};

export const debounce = (
  func: Function,
  wait: number,
  immediate = false,
): ((...args: any) => void) => {
  type Timer = ReturnType<typeof setTimeout>;
  let timeout: Timer | undefined;
  return function (this: any) {
    const args = arguments;
    const later = () => {
      timeout = undefined;
      if (!immediate) func.apply(this, args);
    };
    const callNow = immediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(this, args);
  };
};

/**
 * Is the Daft seller a private seller
 */
export const isPrivateSeller = (seller: Seller | undefined): boolean =>
  Boolean(seller && seller.sellerType === SellerType.PRIVATE);

// useful when dealing with url query parameters, as the next API
// will return a single value when there is only one value or an array
// if more than one. We usually force everything to be an array so we
// know what we're dealing with
export const forceIntoArray = (value: any): any[] =>
  Array.isArray(value) ? value : [value];

export const getPropertyCountForSection = (
  section: SECTIONS,
  propertyCounts: PropertyCounts,
): string => {
  let count = 0;
  switch (section) {
    case SECTIONS.BUY:
      count = propertyCounts.residentialForSale;
      break;
    case SECTIONS.RENT:
      count = propertyCounts.residentialForRent;
      break;
    case SECTIONS.SHARE:
      count = propertyCounts.sharing;
      break;
    case SECTIONS.COMMERCIAL_BUY:
      count = propertyCounts.commercialForSale;
      break;
    case SECTIONS.COMMERCIAL_RENT:
      count = propertyCounts.commercialToRent;
      break;
    case SECTIONS.STUDENT_ACCOMMODATION_RENT:
      count = propertyCounts.studentAccommodationForRent;
      break;
    case SECTIONS.STUDENT_ACCOMMODATION_SHARE:
      count = propertyCounts.studentAccommodationToShare;
      break;
    case SECTIONS.PARKING_BUY:
      count = propertyCounts.parkingForSale;
      break;
    case SECTIONS.PARKING_RENT:
      count = propertyCounts.parkingToRent;
      break;
    case SECTIONS.NEW_HOMES:
      count = propertyCounts.newHomes;
      break;
    case SECTIONS.HOLIDAY_HOMES:
      count = propertyCounts.holidayHomes;
      break;
  }
  return count.toLocaleString();
};

export const getTypeOfListing = (
  listing: Listing | { prs?: SubUnit; newHome?: SubUnit },
) => {
  /**
   * PRS: Private Rental Sector (also known as MUR: Multi-unit Rentals)
   * PRS and New homes have a Parent ad (Development) and Child ad(s)(Property) integrated to it.
   */
  const subUnit = listing && (listing.prs || listing.newHome);

  /**
   * It is a Parent PRS or Parent New Home ad.
   * Only the Child ad(s) of a Development has the object parentDevelopment sent by the BE
   */
  const isDevelopment = Boolean(subUnit && !subUnit.parentDevelopment);
  return isDevelopment ? 'DEVELOPMENT' : 'PROPERTY';
};

export const getParsedJSON = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  })
    .format(value)
    .replace(/\D00(?=\D*$)/, '');
};

export const sortObjects = <T,>(key: keyof T) => {
  return (a: T, b: T) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  };
};

export const IS_PROD =
  ENVIRONMENT_NAME === 'production' && !INTEGRATION_TESTING;
export const IS_PROD_OR_INT_TEST = ENVIRONMENT_NAME === 'production';
export const IS_NOT_PROD =
  ENVIRONMENT_NAME !== 'production' || INTEGRATION_TESTING;
export const IS_NOT_PROD_NOT_INT_TEST = ENVIRONMENT_NAME !== 'production';

export const checkCookieExists = (cookie: string) => {
  if (typeof window === 'undefined') return;
  return document.cookie
    .split(';')
    .some((item) => item.trim().startsWith(cookie));
};

// generate a random 6 digit number for proxy sms code. Only starts with 1-9.
export const generateProxySMSCode = () => {
  return Math.floor(100000 + Math.random() * 900000);
};

/*
 * Tableau script needs to be loaded in the _document.tsx file as of Next 13.5.6 upgrade
 * We only want to load the Tableau script on the routes that use it
 * This function returns a boolean to check if the current route needs the Tableau script
 */
export const checkIfTableauRoute = (currentPath: string): boolean => {
  const routesUsingTableau = [
    '/agents/datahub',
    '/agent-v2/datahub',
    '/agents/bi',
    '/agent-v2/bi',
    '/agents/adperformance',
    '/agent-v2/adperformance',
    '/agents/premierpartner',
    '/agent-v2/premierpartner',
  ];

  // Check if the current path starts with any of the tableau routes
  const isTableauRoute: boolean = routesUsingTableau.some((path) =>
    currentPath.startsWith(path),
  );
  return isTableauRoute;
};

/*
 * The Tableau dashboards data was requested from v1/${section}/dashboards, but the data was hardcoded there
 * We are now moving the hardcoded data to this file and using it in the TableauDashboard component
 */
export const tableauDashboards: Dashboard[] = [
  {
    url: 'https://tableau.dsch.ninja/#/views/BusinessIntelligence/BusinessIntelligence-Supply',
    label: `Supply`,
    section: `supply`,
    query: `?:subscriptions=no&:showShareOptions=false&:embed=y`,
  },
  {
    url: 'https://tableau.dsch.ninja/#/views/BusinessIntelligence/BusinessIntelligence-Demand',
    label: `Demand`,
    section: `demand`,
    query: `?:subscriptions=no&:showShareOptions=false&:embed=y`,
  },
  {
    url: 'https://tableau.dsch.ninja/#/views/MySubscription/MySubscription',
    label: `My Subscription`,
    section: `subscription`,
    query: `?:subscriptions=no&:showShareOptions=false&:embed=y`,
  },
];
