import { PrivateUser } from '../../types';

export const generateAutoLoginUrl = ({
  authServerUrl,
  token,
  params,
}: {
  authServerUrl: string | undefined;
  token: string;
  params: {
    scope: string;
    redirect_uri: string;
    state: string;
    code_challenge: string;
    code_challenge_method: string;
  };
}) => {
  const { scope, redirect_uri, state, code_challenge, code_challenge_method } =
    params;

  if (!authServerUrl) {
    throw new TypeError('authServerUrl required to generate auto login url');
  }
  return `${authServerUrl}/auth/realms/daft/login-actions/action-token?key=${token}&client_id=web-client-new&scope=${scope}&response_type=code&redirect_uri=${redirect_uri}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}`;
};

export const getBuyingBudgetInfoFromUserResponse = (
  userInfo: PrivateUser,
  infoType: string,
): boolean => {
  if (
    userInfo &&
    userInfo.user_details &&
    userInfo.user_details.userAttributes &&
    userInfo.user_details.userAttributes.find((item: any) => {
      return item.key === infoType && item.value !== 'false';
    })
  ) {
    return true;
  }
  return false;
};

export const getBuyingBudgetValueFromUserResponse = (
  userInfo: PrivateUser,
  infoType: string,
): any => {
  let bbValue;
  userInfo &&
    userInfo.user_details &&
    userInfo.user_details.userAttributes &&
    userInfo.user_details.userAttributes.find((item: any) => {
      if (item.key === infoType) {
        bbValue = item.value;
      }
    });
  return bbValue;
};
