const BREAKPOINTS = ['xs', 'sm', 'md', 'lg'];

export type Breakpoints<T> = {
  xs: T;
  sm: T;
  md: T;
  lg: T;
};

export type BreakpointsOptional<T> = {
  xs?: T;
  sm?: T;
  md?: T;
  lg?: T;
};

export type BreakpointValue = 'xs' | 'sm' | 'md' | 'lg';

const forEachBreakpoint = (callback: (item: BreakpointValue) => void) => {
  BREAKPOINTS.forEach((breakpointItem) => {
    const breakpoint = breakpointItem as BreakpointValue;
    return callback(breakpoint);
  });
};

export { forEachBreakpoint };
